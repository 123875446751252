import homeImg from "../assets/home_page.jpg";
import { HashLink } from "react-router-hash-link";

export default function BPIHome({ language, toggleLanguage }) {
  return (
    <>
      {toggleLanguage != null ? (
        <div className="toggle-div">
          <p>Español?</p>
          <label className="switch">
            <input type="checkbox" onChange={toggleLanguage} />
            <span className="slider round"></span>
          </label>
        </div>
      ) : null}
      <div className="home-page">
        <div className="home-page-msg">
          <div className="phrase">
            {language === "en" ? (
              <span className="light">Because </span>
            ) : (
              <span className="light">Porque tu </span>
            )}
            <span className="highlight">
              {language === "en" ? "Your Work " : "Trabajo "}
            </span>
            <span className="light">
              {language === "en" ? "deserves " : "merece "}
            </span>
            {language === "en" ? (
              <span className="archivo-black-logo">IT that </span>
            ) : (
              <>
                <span className="archivo-black-logo">Tecnología </span>
                <span className="light">que </span>
              </>
            )}
            <span className="bold-highlight">
              {language === "en" ? "Works." : "Funcione"}
            </span>
          </div>
          <p>
            {language === "en"
              ? "Your productivity matters—let us handle the tech, so you can focus on what you do best."
              : "Tu productividad importa—dejanos la technologia, para que tu te puedas enfocar en lo que mejor haces."}
          </p>
          <div style={{ margin: "60px 0" }}>
            <HashLink to="#services-page" className="bpi-button">
              {language === "en" ? "Learn more!" : "Aprende mas!"}
            </HashLink>
          </div>
        </div>
        <img src={homeImg} alt="Technical Support" className="home-page-img" />
      </div>
    </>
  );
}
