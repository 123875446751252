import aboutImg from "../assets/about_page.jpg";

export default function BPIAbout({ language }) {
  return (
    <div className="about-page" id="about-page">
      <div className="about-text">
        <div className="phrase">
          <span className="light">
            {language === "en" ? "About" : "Sobre Nosotros"} <br />
          </span>
          <span className="bold-highlight">
            BYTE PRECISION <br />
          </span>
          <span className="archivo-black-logo">IT </span>
        </div>
        <p className="about-extract">
          {language === "en"
            ? "At Byte Precision IT, we know how frustrating technology issues can be. Our journey began at Best Buy Geek Squad, where we gained hands-onexperience solving a variety of tech challenges for the public. Drivento do more, we expanded our expertise through advanced studies in software engineering, cybersecurity, and IT. Now, we're focused on providing exceptional IT support to professionals who rely on their hardware to excel. Whether it's troubleshooting, optimization, or advice, we're here to ensure your technology empowers you to do your best work. With Byte Precision IT, you can count on expert solutions and a partner who understands your needs."
            : "En Byte Precision IT, entendemos lo frustrante que pueden ser los problemas tecnológicos. Nuestro viaje comenzó en Best Buy Geek Squad, donde adquirimos experiencia práctica resolviendo una variedad de desafíos tecnológicos para el público. Impulsados por el deseo de hacer más, ampliamos nuestra experiencia a través de estudios avanzados en ingeniería de software, ciberseguridad e informática. Ahora, nos enfocamos en brindar soporte de IT excepcional a profesionales que dependen de su hardware para sobresalir. Ya sea solucionando problemas, optimizando o brindando asesoramiento, estamos aquí para garantizar que tu tecnología te permita dar lo mejor de ti. Con Byte Precision IT, puedes confiar en soluciones expertas y un socio que entiende tus necesidades."}
        </p>
      </div>
      <img className="home-page-img" src={aboutImg} alt="BYTE PRECISION IT" />
    </div>
  );
}
