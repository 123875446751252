import backup from "../assets/backups-transparent-cropped.png";
import tuneup from "../assets/tuneups-transparent.png";
import remote from "../assets/remote_support-transparent.png";
import { useEffect, useState } from "react";

export default function BPIServices({ language }) {
  const [isMobile, setIsMobile] = useState(false);

  // Track window size and update state
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    handleResize(); // Check size on initial render
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <div className="services-page" id="services-page">
      <h1 className="page-heading">
        {language === "en" ? "Our services" : "Nuestros Servicios"}
      </h1>
      <div className="card-carousel">
        <div className="card">
          <img className="card-image" src={tuneup} alt="Tuneup" />
          <div className="container">
            <h4>
              <b>Tune-up</b>
            </h4>
            <p>
              {language === "en"
                ? "A computer tune-up is like giving your computer a check-up and cleaning to make it run faster and more efficiently. It involves removing unnecessary files, fixing software issues, updating programs, removing those pesky pop-ups, and ensuring there are no viruses or malware slowing it down. Think of it as tidying up and optimizing your computer so it works like it's new again!"
                : "Un ajuste de computadora es como hacerle un chequeo y una limpieza a tu computadora para que funcione más rápido y de manera más eficiente. Consiste en eliminar archivos innecesarios, solucionar problemas de software, actualizar programas, eliminar esas molestas ventanas emergentes y asegurarse de que no haya virus ni malware ralentizándola. Piénsalo como ordenar y optimizar tu computadora para que funcione como si fuera nueva nuevamente."}
            </p>
          </div>
        </div>
        <div className="card">
          {isMobile ? (
            <>
              <img className="card-image" src={backup} alt="Backup" />
              <div className="container">
                <h4>
                  <b>Data Backup</b>
                </h4>
                <p>
                  {language === "en"
                    ? "A data backup is a copy of your important files, like documents, photos, or videos, saved in a safe place. This way, if your computer crashes, gets lost, or is damaged, you won't lose your files. Backups can be stored on an external drive or in the cloud, giving you peace of mind that your data is secure and easy to restore."
                    : "Una copia de seguridad de datos es una copia de tus archivos importantes, como documentos, fotos o videos, guardada en un lugar seguro. De esta manera, si tu computadora falla, se pierde o se daña, no perderás tus archivos. Las copias de seguridad pueden almacenarse en un disco externo o en la nube, brindándote tranquilidad al saber que tus datos están seguros y son fáciles de recuperar."}
                </p>
              </div>
            </>
          ) : (
            <>
              <div className="container">
                <h4>
                  <b>Data Backup</b>
                </h4>
                <p>
                  {language === "en"
                    ? "A data backup is a copy of your important files, like documents, photos, or videos, saved in a safe place. This way, if your computer crashes, gets lost, or is damaged, you won't lose your files. Backups can be stored on an external drive or in the cloud, giving you peace of mind that your data is secure and easy to restore."
                    : "Una copia de seguridad de datos es una copia de tus archivos importantes, como documentos, fotos o videos, guardada en un lugar seguro. De esta manera, si tu computadora falla, se pierde o se daña, no perderás tus archivos. Las copias de seguridad pueden almacenarse en un disco externo o en la nube, brindándote tranquilidad al saber que tus datos están seguros y son fáciles de recuperar."}
                </p>
              </div>
              <img className="card-image" src={backup} alt="Backup" />
            </>
          )}
        </div>
        <div className="card">
          <img className="card-image" src={remote} alt="Remote support" />
          <div className="container">
            <h4>
              <b>Remote Support & Consultations</b>
            </h4>
            <p>
              {language === "en"
                ? "Remote support and consultations allow a technician to help you with your computer or tech issues without being in the same location. Using a secure connection, they can access your computer, troubleshoot problems, install software, or provide advice. It's a convenient way to get expert help from the comfort of your home or office."
                : "El soporte remoto y las consultas permiten que un técnico te ayude con problemas de tu computadora o tecnología sin necesidad de estar en la misma ubicación. Usando una conexión segura, pueden acceder a tu computadora, resolver problemas, instalar software o brindarte asesoramiento. Es una forma conveniente de obtener ayuda experta desde la comodidad de tu hogar u oficina."}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
