export default function BPIFooter({ language }) {
  return (
    <footer className="footer">
      <div className="footer-content">
        <p>&copy; 2024 Byte Precision IT. All rights reserved.</p>
        <nav className="footer-links">
          <a href="#services-page">Services</a>
          <a href="#contact">Contact</a>
          <a href="#privacy">Privacy Policy</a>
        </nav>
      </div>
    </footer>
  );
}
