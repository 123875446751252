import { Link } from "react-router-dom";
function NotFoundPage() {
  return (
    <div
      style={{
        display: "flex",
        fontSize: "40px",
        justifyContent: "center",
        flexDirection: "column",
      }}
    >
      404 Error Not Found
      <Link to="/">Home</Link>
    </div>
  );
}

export default NotFoundPage;
